import React, { FC } from 'react';
import { ThemeProvider, createMuiTheme } from '@titan/ui';
import { CssBaseline } from '@material-ui/core';
import * as windTreColors from './theme/windTreColors.json';
import * as windTreHeaderColors from './theme/windTreHeaderColors.json';

export const windTreTheme = createMuiTheme({
  spacing: 10,
  palette: {
    type: 'light',
    divider: windTreColors.divider.color,
    background: {
      default: windTreColors.background.default.color,
      paper: windTreColors.background.popupsAndDrawers.color,
    },
    primary: {
      main: windTreColors.primary.color,
      100: windTreColors.darkGray.color,
      200: windTreColors.white.color,
      300: windTreColors.lightGray.color,
      400: windTreColors.black.color,
      A100: windTreHeaderColors.lightThemeDarkTextOnLightBackground.color,
      A200: windTreHeaderColors.lightThemeLightTextOnDarkBackground.color,
      A400: windTreHeaderColors.darkThemeDarkTextOnLightBackground.color,
      A700: windTreHeaderColors.darkThemeLightTextOnDarkBackground.color,
      500: windTreHeaderColors.headerBackgroundColorLightThemeSecondaryPage.color,
      600: windTreHeaderColors.headerBackgroundColorLightThemeMainPage.color,
      700: windTreHeaderColors.headerBackgroundColorDarkThemeSecondaryPage.color,
      800: windTreHeaderColors.headerBackgroundColorDarkThemeMainPage.color,
    },
    secondary: {
      main: windTreColors.accent.color,
    },
  },

  overrides: {
    MuiBottomNavigation: {
      root: {
        backgroundColor: windTreColors.background.bottomNav.color,
      },
    },
    MuiListItemIcon: {
      root: {
        color: windTreColors.primary.color,
      },
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});
const getTheme = () => {
    return windTreTheme;
};

export const AppThemeProvider: FC = props => (
  <ThemeProvider theme={getTheme()}>
    <CssBaseline />
    {props.children}
  </ThemeProvider>
);
