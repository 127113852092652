import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

window.addEventListener('load', function() {
  setTimeout(function() {
    const element = document.getElementById('gradientText');
    if (!!element) {
      generateGradientText(element);
    }
  }, 1000);
});

function generateGradientText(element: any) {
  const text = element.innerText;
  element.innerHTML = '';
  const start = 270;
  const interval = 20 / text.length;
  for (let i = 0; i < text.length; i++) {
    const charElem = document.createElement('span');
    const hue = Math.round(start + interval * i);
    charElem.style.color = 'hsl(' + hue + ',100%,50%)';
    charElem.innerHTML = text[i];
    element.appendChild(charElem);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
