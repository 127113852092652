import translations from './translationFiles/';
import { HttpClientRequestConfig } from '@titan/http';

export const AppVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0.0-0';
export const PartnerIdName = 'three';
export const TrackingUUID = process.env.REACT_APP_TRACKING_UUID ? process.env.REACT_APP_TRACKING_UUID : '';

if (!process.env.REACT_APP_BACKEND_BASE_URL) {
  throw new Error('Base URL not set');
}
if (!process.env.REACT_APP_TRACKING_BASE_URL) {
  throw new Error('Tracking URL not set');
}

const paramCarrierCode = new URLSearchParams(window.location.search).get('carrierCode');
if (paramCarrierCode !== null) {
  sessionStorage.setItem('carrierCode', paramCarrierCode);
}

const savedCode = paramCarrierCode ? paramCarrierCode : sessionStorage.getItem('carrierCode');
const carrierHeader = savedCode !== null ? { 'X-CARRIER-CODE': savedCode } : {};
const appUserIdHeader = !!process.env.REACT_APP_BACKEND_BASE_URL
  ? { 'X-APP-USERID': process.env.REACT_APP_TRACKING_UUID }
  : {};
const clientTypeHeader = { 'X-CLIENT-TYPE': 'web' };

export const httpClientConfig: HttpClientRequestConfig = {
  baseURL: getBaseUrl(process.env.REACT_APP_BACKEND_BASE_URL),
  backOff: {
    retries: 5,
    initialDelay: 2,
  },
  headers: {
    ...carrierHeader,
    ...clientTypeHeader,
    ...appUserIdHeader,
  },
};
export const httpTrackingClientConfig: HttpClientRequestConfig = {
  baseURL: getBaseUrl(process.env.REACT_APP_TRACKING_BASE_URL),
  backOff: {
    retries: 5,
    initialDelay: 2,
  },
  headers: {
    ...carrierHeader,
    ...clientTypeHeader,
    ...appUserIdHeader,
  },
};

export const translationsPerCountry: Map<string, any> = new Map([['default', translations]]);

function getBaseUrl(url: string) {
  return process.env.NODE_ENV === 'development' ? 'http://' + window.location.hostname + ':3001/' + url : url;
}
