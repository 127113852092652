import React, { useEffect } from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Route, RoutesProvider } from '@titan/router';
import { HashRouter as Router } from 'react-router-dom';
import { LanguageProvider, ErrorHandlerProvider, HttpServices } from '@titan/generic';
import { WindTreScreen } from './Screens';
import { TouchListener } from '@titan/ui';
import { AppThemeProvider } from './theme';
import { appRoutesProvider } from './routes';
import { httpServicesProvider } from './http';
import translations from './translationFiles/';
const App: React.FC = () => {
  useEffect(() => {
    const manifestDefault = process.env.PUBLIC_URL + '/manifest.json';
    // @ts-ignore
    document.querySelector('#manifest-placeholder').setAttribute('href', manifestDefault);
  }, []);

  const getScreen = () => {
      return WindTreScreen;
    }
  return (
    <AppThemeProvider>
      <ErrorHandlerProvider>
        <RoutesProvider.Provider value={appRoutesProvider}>
          <HttpServices.Provider value={httpServicesProvider}>
            <LanguageProvider clientTranslations={translations}>
              <TouchListener>
                <Router>
                  <Route exact={true} path={'/'} component={getScreen()} />
                </Router>
              </TouchListener>
            </LanguageProvider>
          </HttpServices.Provider>
        </RoutesProvider.Provider>
      </ErrorHandlerProvider>
    </AppThemeProvider>
  );
};

export default App;
