import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MicroAppDownload } from '@titan/ui/dist/Template';
import { HttpServices, Language } from '@titan/generic';
import { pluck, MobileConfigService, MobileConfig, TrackingServiceNoAuth } from '@titan/http-services';

export const WindTreScreen: React.FC = () => {
  const [mobileConfig, setMobileConfig] = useState<MobileConfig | undefined>();
  const httpServices = useContext(HttpServices);
  const mobileConfigService: MobileConfigService<MobileConfig> = httpServices.get(MobileConfigService);
  const trackingService: TrackingServiceNoAuth = React.useContext(HttpServices).get(TrackingServiceNoAuth);
  const language = useContext(Language).iso;
  const playStoreUrlPrefix = 'https://play.google.com/store/apps/details';

  useEffect(() => {
    mobileConfigService
      .getConfig('1.0.0', language)
      .then(pluck('data'))
      .then(setMobileConfig);
  }, [mobileConfigService, language]);

  useEffect(() => {
    if (!!mobileConfig && !!mobileConfig.operationId && !!mobileConfig.operatorCountryCode && !!mobileConfig.country) {
      trackingService.appendTrackingParams(
        mobileConfig.operationId,
        mobileConfig.operatorCountryCode,
        mobileConfig.country,
      );
    }
  }, [mobileConfig, trackingService]);

  useEffect(() => {
    if (!!mobileConfig && !!mobileConfig.appDownloadUrl && mobileConfig.appDownloadUrl.startsWith(playStoreUrlPrefix)) {
      window.location.replace(mobileConfig.appDownloadUrl);
    }
  }, [mobileConfig]);

  const onClickDownload = useCallback(() => {
    if (!!mobileConfig && !!mobileConfig.appDownloadUrl) {
      trackingService.sendEvent(
        new CustomEvent('titan-event', {
          detail: {
            type: 'DownloadAppSuccess',
            payload: {},
          },
        }),
      );
      window.location.replace(mobileConfig.appDownloadUrl);
    }
  }, [mobileConfig, trackingService]);

  return !!mobileConfig && mobileConfig.appDownloadUrl ? (
    <>
      <MicroAppDownload
        onClickDownload={onClickDownload}
        fontFamily={'Montserrat'}
        topAssetUrl={'windTre_background.png'}
        logoUrl={'windTre_logo.png'}
        sliderUrl={'windTre_slider.svg'}
      />
    </>
  ) : (
    <></>
  );
};
