import { httpClientConfig, httpTrackingClientConfig } from './config';
import { createHttpServicesProvider } from '@titan/generic';
import {
  MobileConfigServiceNoAuth,
  MobileServiceNoAuth,
  MobileService,
  MobileConfigService,
  TrackingServiceNoAuth,
} from '@titan/http-services';
import { HttpClient } from '@titan/http';
import { AppVersion, PartnerIdName, TrackingUUID } from './config';

const trackingService = new TrackingServiceNoAuth(
  AppVersion,
  PartnerIdName,
  new HttpClient(httpTrackingClientConfig),
  TrackingUUID,
);
const httpClient = new MobileServiceNoAuth(new HttpClient(httpClientConfig));
const httpConfigClient = new MobileConfigServiceNoAuth(new HttpClient(httpClientConfig));
const services = new Map()
  .set(MobileConfigService, httpConfigClient)
  .set(MobileService, httpClient)
  .set(TrackingServiceNoAuth, trackingService);
export const httpServicesProvider = createHttpServicesProvider(services);
